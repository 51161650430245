<template>
  <div class="text-dark">
    <b-container fluid>
      <div
        v-if="
          detailSubscription &&
          detailSubscription.payment_status === 'pending' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'bni_va'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Transaksi </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-warning p-1 rounded-pill fw-bold-700">
                  {{
                    detailSubscription.payment_status === "pending"
                      ? "Menunggu Pembayaran"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Metode Pembayaran </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.xendit_payment.payment_method === "bni_va"
                    ? "BNI VA - "
                    : ""
                }}
                {{ detailSubscription.xendit_payment.va_account_number }}
                <feather-icon
                  icon="CopyIcon"
                  class="text-primary cursor-pointer"
                  size="16"
                  @click="
                    copyToClipboard(
                      detailSubscription.xendit_payment.va_account_number
                    )
                  "
                />
                <div v-if="copied" class="badge badge-light-success">
                  Berhasil disalin!
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Waktu Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.xendit_payment.created_at }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Bayar Sebelum </b-col>
              <b-col class="text-right text-danger">
                {{ detailSubscription.xendit_payment.payment_expired_at }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Cara Bayar </b-col>
            </b-row>
            <div class="card p-1" v-b-toggle.collapse-1 variant="primary">
              <div class="d-flex align-items-center">
                <b-img
                  :src="require('/src/assets/images/logo/bni.png')"
                  alt="bca"
                  style="width: 4rem"
                />
                <span class="ml-1">
                  Bank Negara Indonesia - Virtual Account
                </span>
                <span class="ml-auto">
                  <feather-icon icon="ChevronDownIcon" size="16" />
                </span>
              </div>
              <b-collapse id="collapse-1">
                <hr />
                <div class="size16 fw-bold-900 ml-1">Melalui ATM</div>
                <ol>
                  <li>Masukkan kartu ATM anda</li>
                  <li>Pilih bahasa</li>
                  <li>Masukkan PIN ATM anda</li>
                  <li>Pilih "Menu Lainnya"</li>
                  <li>Pilih "Transfer"</li>
                  <li>
                    Pilih jenis rekening yang akan anda gunakan (contoh: "Dari
                    Rekening Tabungan")
                  </li>
                  <li>Pilih "Virtual Account Billing"</li>
                  <li>
                    Masukkan Nomor Virtual Account anda contoh:
                    <b>8808988556620621</b>
                  </li>
                  <li>
                    Tagihan yang harus dibayarkan akan muncul pada layar
                    konfirmasi
                  </li>
                  <li>Konfirmasi, apabila telah sesuai, lanjutkan transaksi</li>
                  <li>
                    Setelah transaksi anda selesai, invoice ini akan diupdate
                    secara otomatis. Proses ini mungkin memakan waktu hingga 5
                    menit
                  </li>
                </ol>
                <div class="size16 fw-bold-900 ml-1">
                  Melalui Aplikasi BNI Mobile
                </div>
                <ol>
                  <li>Akses BNI Mobile Banking melalui handphone</li>
                  <li>Masukkan User ID dan Password</li>
                  <li>Pilih menu "Transfer"</li>
                  <li>
                    Pilih menu "Virtual Account Billing", lalu pilih rekening
                    debet
                  </li>
                  <li>
                    Masukkan Nomor Virtual Account anda contoh:
                    <b>8808988556620621</b>
                    pada menu "Input Baru"
                  </li>
                  <li>
                    Tagihan yang harus dibayarkan akan muncul pada layar
                    konfirmasi
                  </li>
                  <li>Konfirmasi transaksi dan masukkan Password Transaksi</li>
                  <li>
                    Setelah transaksi anda selesai, invoice ini akan diupdate
                    secara otomatis. Proses ini mungkin memakan waktu hingga 5
                    menit
                  </li>
                </ol>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="
          detailSubscription &&
          detailSubscription.payment_status === 'success' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'bni_va'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right" @click="print">
                <b-button class="bg-white border-8">
                  {{ detailSubscription.invoice_number }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-primary p-1 rounded-pill fw-bold-700">
                  {{
                    detailSubscription.payment_status === "success"
                      ? "Aktif"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.xendit_payment.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="
          detailSubscription &&
          detailSubscription.payment_status === 'canceled' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'bni_va'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-danger p-1 rounded-pill fw-bold-700 text-white">
                  {{
                    detailSubscription.payment_status === "canceled"
                      ? "Dibatalkan"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.xendit_payment.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div
        v-else-if="
          detailSubscription &&
          detailSubscription.payment_status === 'expired' &&
          detailSubscription.xendit_payment &&
          detailSubscription.xendit_payment.payment_method === 'bni_va'
        "
      >
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span
                  class="secondary-2 p-1 rounded-pill fw-bold-700 text-white"
                >
                  {{
                    detailSubscription.payment_status === "expired"
                      ? "Kedaluwarsa"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.sunscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.xendit_payment.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <InvoiceSubscription
      :detailSubscription="detailSubscription"
      class="d-none"
    />
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";

import ModalHeader from "@/components/ModalHeader.vue";
import InvoiceSubscription from "@/components/InvoiceSubscription.vue";

export default {
  components: {
    ModalHeader,
    BModal,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BCard,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BCollapse,
    InvoiceSubscription,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      copied: false,
    };
  },
  props: {
    detailSubscription: Object,
  },
  methods: {
    copyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
    async print() {
      const { hostname, protocol, port } = window.location;

      const noLandscapeOptions = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          `${protocol}//${hostname}${
            port !== "" ? `:${port}` : ""
          }/bootstrap.css`,
        ],
        timeout: 1000,
        autoClose: true,
        windowTitle: window.document.title,
      };

      await this.$htmlToPaper("contentPrintSubscription", noLandscapeOptions);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.secondary-2 {
  background-color: $secondary-2;
}
</style>
